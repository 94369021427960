<template>
  <svg
    class="fill-current"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.399902 7.60003L3.46092 3.33145V4.54517L0.532106 0.400024H2.40329L4.42702 3.29031L3.64397 3.3006L5.63719 0.400024H7.42702L4.51855 4.47317V3.28003L7.5999 7.60003H5.69821L3.60329 4.54517H4.35584L2.29143 7.60003H0.399902Z"
    />
  </svg>
</template>
